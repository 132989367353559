import axios from "axios"
import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT } from "../constants/userConstants"


export const fetchUser = () => async (dispatch) => {
   
    try {

        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }

        const { data } = await axios.get(`/api/current-user`, config)
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: USER_LOGIN_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}

export const logout = () => async (dispatch) => {

    try {
        localStorage.removeItem('userInfo');
        const response = await axios.get('/api/logout')
        if(response.status === 200) {
            dispatch({ type: USER_LOGOUT })
        }
    } catch (error) {
        console.error(error)
    }
}