import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SearchBar from '../components/SearchBar'
import ProductList from '../components/ProductList'
import { fetchUser, logout } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { loadProductsFromBexio } from '../actions/productsActions'

const Productscreen = () => {

    const [ searchResults, setSearchResults ] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const bexioProducts = useSelector(state => state.bexioProducts);
    const { loading, products, error } = bexioProducts;

    const userLogin = useSelector(state => state.userLogin);
    const { loading:loadingUser, userInfo } = userLogin;

    useEffect(() => {
        dispatch(fetchUser())
    },[])

    useEffect(() => {
        if(!products) {
            dispatch(loadProductsFromBexio())
        } 
        if(products) {
            setSearchResults(products)
        }
    },[dispatch, products])

    return (
        <>
            <Meta />
            <h1>Produkte</h1>
            {error && <Message variant='danger' children={error} />}
            <SearchBar products={products} setSearchResults={setSearchResults}/>
            {loading ? <Loader /> : (<ProductList searchResults={searchResults} />)}
        </>
    )
}

export default Productscreen
